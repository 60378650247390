/*
 *  External library overrides
 *
 */

@import "overrides/bootstrap/bootstrap";
@import "slick.scss";
@import "overrides/slick-carousel/slick-theme";

/*
 *  Variables and mixins
 *
 */

@import "partials/variables";
@import "partials/mixins";

/*
 *  Layout
 *
 */

// @import "partials/global";
// @import "partials/site";
// @import "partials/header";
// @import "partials/footer";
@import "partials/ie";
@import "partials/demo";

/*
 *  Elements
 *
 */

@import "partials/fonts";


@font-face {
  font-family: 'MyWebFont';
  src:  url('../fonts/OpenSans-Regular-webfont.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular-webfont.woff') format('woff');
}


/*
 *  Components
 *
 */

@import "../components/announcement/announcement";
