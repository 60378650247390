// // DO NOT EDIT DIRECTLY!
  //Generated by Hive
  //from gulpfile.js\tasks\iconFont\template.scss

@font-face {
  font-family: font-icons;
  src: url("../fonts/font-icons.eot");
  src: url("../fonts/font-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/font-icons.woff") format("woff"), url("../fonts/font-icons.ttf") format("truetype"), url("../fonts/font-icons.svg#font-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin icon($content: "") {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: "font-icons";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    @content;
  }
}

.icon {
  @include icon;
}

// Save variable
$icon-facebook: "\EA01";

// Save mixin
@mixin facebook {
  @include icon($icon-facebook) {
    @content;
  }
}

// Expose as class
.icon-facebook:before {
  content: $icon-facebook;
}
// Save variable
$icon-instagram: "\EA02";

// Save mixin
@mixin instagram {
  @include icon($icon-instagram) {
    @content;
  }
}

// Expose as class
.icon-instagram:before {
  content: $icon-instagram;
}
// Save variable
$icon-twitter: "\EA03";

// Save mixin
@mixin twitter {
  @include icon($icon-twitter) {
    @content;
  }
}

// Expose as class
.icon-twitter:before {
  content: $icon-twitter;
}

