/*
*  This is a demo stylesheet that should be removed when you begin
*  active development of your project.
*
*/
body {
  @include font-smoothing;
  font-family: MyriadPro-Regular, 'Myriad Pro Regular', MyriadPro, 'Myriad Pro', Helvetica, Arial, sans-serif;
  border-top: 8px solid #3a215b;
  border-left: 8px solid #3a215b;
  border-right: 8px solid #3a215b;
  overflow-x: hidden;
  height: 100vh;
}
[class*=' col-']{
  transition: width 0.3s ease-in-out, margin 0.3s ease-in-out;
}
/*********** Animated Burger menu *************/
.navbar-default .navbar-toggle {
    border-color: #fff;
    background: white;
}
.navbar-default .navbar-toggle .icon-bar{
  background: #5a3d7f;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #5a3d7f;
    box-shadow: none;
}

.navbar-toggle .icon-bar:nth-of-type(3) {
	  top: 1px;
}

.navbar-toggle .icon-bar:nth-of-type(4) {
  	top: 2px;
}

.navbar-toggle .icon-bar {
	  position: relative;
	  transition: all 500ms ease-in-out;
}

.navbar-toggle.active .icon-bar:nth-of-type(2) {
	  top: 6px;
	  transform: rotate(45deg);
}

.navbar-toggle.active .icon-bar:nth-of-type(3) {
	  background-color: transparent;
}

.navbar-toggle.active .icon-bar:nth-of-type(4) {
	  top: -6px;
	  transform: rotate(-45deg);
}
/*********** Animated Burger menu *************/

.navbar-brand{
  padding: 15px 15px 0 7.5px;
  height: auto;
  transition: padding .3s ease-in-out;
  @media (max-width: $screen-sm-min){
    padding: 7px 15px 0 15px;
  }
  > img{
    height: auto;
    width: 100%;
    max-width: 148px;
    margin-top: 0px;
      @media (max-width: $screen-md-min){
        max-width: 118px;
      }
  }
}

.container-fluid > .navbar-collapse {
  margin-right: -30px;
  margin-left: -30px;
  float: right;
  @media (max-width: $screen-sm-min){
    float: none;
    margin-right: -15px;
    margin-left: -15px;
  }
}
.navbar-default{
  background: #5a3d7f;
  color: #fca951;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 45px;
  letter-spacing: .75px;
  .navbar-nav > li {
    height: 70px;
    > a{
      color: #fca951;
      font-family: 'Rockwell', Courier, Georgia, Times, Times New Roman, serif;
      padding-top: 25px;
      padding-bottom: 25px;
      font-size: 18px;
      font-weight: normal;
      &:hover, &:active, &:focus{
        color: white !important;
        background: #3a215b !important;
      }
      @media (max-width: $screen-md-min){
        font-size: 16px;
        letter-spacing: .25px;
      }
      @media (max-width: 862px){
        font-size: 12px;
        letter-spacing: 0;
      }
      @media (max-width: $screen-sm-min){
        font-size: 18px;
      }
      > span > img{
        transition: opacity .3s ease-in-out;
        opacity: 0;
        height: 0px;
        width: 0px;
        margin-right: 3px;
      }
    }
  }
  .navbar-utility{
    > li {
      border-left: 1px solid #3a215b;
      @media (max-width: $screen-sm-min){
        border: 0px;
      }
      > a{
        height: 70px;
        color: white;
        font-size: 12px;
        font-family: helvetica;
        padding: 11px 15px;
        @media (max-width: $screen-md-min){
          font-size: 10px;
        }
        @media (max-width: 862px){
          font-size: 10px;
        }
        @media (max-width: $screen-sm-min){
          font-size: 12px;
          padding-top: 25px;
          padding-bottom: 25px;
        }
        > i{
          font-family: fontAwesome;
          font-size: 22px;
          text-align: center;
          color: #fff;
          display: block;
          margin-bottom: 5px;
          @media (max-width: $screen-sm-min){
            text-align: left;
            display: inline;
            margin-bottom: 0px;
            margin-right: 15px;
          }
        }
      }
      &:nth-child(3){
        background: #fca951;
        > a{
          color: #5a3d7f;
          &:hover{
            color: white;
          }
        }
      }
    }
  }
}

.navbar-default .navbar-nav > .active {
  > a{
    color: white;
    background: #3a215b;
    &:hover, &:active, &:focus{
      color: white;
      background: #3a215b;
    }
    >span > img{
      opacity: 1;
      height: 15px;
      width: 15px;
      @media (max-width: $screen-md-min){
        height: 12px;
        width: 12px;
      }
      @media (max-width: $screen-sm-min){
        display: none;
      }
    }
  }
}

/*********** Mega Nav Styles ************/
.bs-mega-nav.active{
  display: block;
}
.bs-mega-nav{
  display: none;
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  background: rgba(58, 33, 91, 0.99);
  padding-top: 1em;
  padding-bottom: 100vh;
  z-index: 100;
  > .container .row{
    border-bottom: 1px solid #5a3d7f;
    margin-right: 0px;
    margin-left: 0px;
  }
  .mega-sub-item.active a{
    color: white !important;
    border-color: #fca951 !important;
  }
  .mega-subnav{
    .mega-sub-item{
    padding-bottom: 15px;
    width: 25%;
    float: left;
    > a{
      font-size: 18px;
      letter-spacing: 1.0px;
      text-decoration: none;
      color: #fca951;
      border-bottom: 4px solid transparent;
      padding-bottom: 10px;
      padding-right: 7.5px;
      padding-left: 7.5px;
      &:hover, &:active{
        color: white;
        border-bottom: 4px solid #fca951;
      }
      @media (max-width: $screen-md-min){
        font-size: 16px;
      }
      @media (max-width: 862px){
        font-size: 12px;
      }
    }
    .active{
      color: white;
      border-bottom: 4px solid #fca951;
    }
  }
}
}
.intro.active{
  display: block;
}
.intro{
  display: none;
  margin-top: 25px;
  h3{
    font-family: 'Rockwell', Courier, Georgia, Times, Times New Roman, serif;
    color: #fca951;
    font-size: 20px;
    text-align: right;
  }
  .intro-wrap{
    text-align: center;
    img{
      height: auto;
      width: 200px;
    }
  .msg-intro{
    top: 128px;
    right: 56px;
    background: #5a3d7f;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    font-family: 'Rockwell', Courier, Georgia, Times, Times New Roman, serif;
    font-size: 22px !important;
    color: #fff;
    line-height: 72px;
    text-align: center;
    position: absolute;
  }
}
  p{
    text-align: right;
    color: white;
    font-size: 12px;
    &:before{
      content:'\2190';
      color: #5a3d7f;
      font-size: 32px;
      position: absolute;
      left: 0;
      bottom: 0px;
    }
  }

}
.cat-item.active a, .subcat-item.active a, {
  font-weight: bold;
  border-bottom: 1px solid white;
  color: white;
  &:focus{
    text-decoration: none;
  }
}
/** Unstyled **/
.mega-catnav-item-unstyled ul li:before{
  color: transparent;
  padding-right: 0px;
  font-size: 0px !important;
}
.mega-catnav-item.active{
  display: block;
}
.mega-catnav-item-unstyled.active{
  display: block;
}
.mega-catnav-item, .mega-catnav-item-unstyled{
  display: none;
  ul{
    font-size: 16px;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    li{
      display: flex;
      list-style-type: none;
      align-items: center;
      width: 100%;
      &:before{
        content: '\0025cb';
        font-size: 42px;
        line-height: 42px;
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        padding-right: 3.5px;
      }
        a {
          color: #9d89b6;
          font-size: 14px;
          letter-spacing: .75px;
          cursor: pointer;
          margin-top: 5px;
          padding-bottom: 5px;
          width: 100%;
          &:hover, &:focus, &:active{
            text-decoration: none;
          }
          @media (max-width: 862px){
            font-size: 12px;
          }
          &:hover{
            color: white;
            text-decoration: none;
            font-weight: bold;
            border-bottom: 1px solid white;
          }
        }
      }
    }
}
.meganav-link-custom.active{
  display: block;
}
.meganav-link-custom{
  display: none;
  background: #1b0935;
  margin-top: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
    h1{
      color: white;
      font-size:60px;
      font-family: Rockwell;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
      margin-bottom:10px;
    }
    h3{
      font-size:20px;
      font-family: Rockwell;color: #9d89b6;

    }
    .legalTxt{
      font-size:8px;
      color: white;
      font-style: italic;
      border-bottom: 1px solid #3a215b;
      padding-bottom: 5px;
    }
  p{
    color: white;
    font-size: 12px
  }
  .btn{}
  .btn-primary{
    width: 100%;
    background: #fca951;
    margin-bottom: 15px;
    border: 0px;
    padding: 7.5px;
  }
  .sub{
    bottom: 0;
  }
}


header {
  background-color: #4d2e6a;
  margin-top: 0px;
  padding-bottom: 0px;
}
.heroWrapper{
  margin-bottom: 25px;
  .hero{
    @media (max-width: $screen-md-min){
      margin-bottom: 25px;
    }
    .heroOverlay{
      position: absolute;
      left: 15px;
      top: 15px;
      h1{
        color: #3a215b;
        font-family: 'Rockwell', Courier Bold, Courier, Georgia, Times, Times New Roman, serif;
        font-weight: bold;
        margin-top: 0;
      }
      p{
        color: #3a3a3a;
        font-size: 12px;
        line-height: 22px;
      }
      button{
        background: #fca951;
        color: white;
        font-family: 'Rockwell', Courier Bold, Courier, Georgia, Times, Times New Roman, serif;
        font-weight: bold;
        border: 1px solid #fca951;
        border-radius: 4px;
        padding: 7.5px 30px;
        letter-spacing: 1.25px;
      }
    }
  }
  .heroFeatureWrapper{
    .heroFeature{
      &:nth-child(1) {
        margin-bottom: 2.5%;
        @media (max-width: $screen-lg-min){
          margin-bottom: 1%;
        }
      }
      .caption{
        padding: 15px 7.5px;
        font-family: 'Rockwell', Courier Bold, Courier, Georgia, Times, Times New Roman, serif;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background: #4d2e6a;
        color: white;
      }
    }
  }
}

.announcementWrapper{
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 25px;
  @media (max-width: $screen-sm-min){
    display: block;
  }
  .announcementTitle{
    background: #4d2e6a;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    @media (max-width: $screen-sm-min){
      padding: 15px;
    }
    img{
      height: 30px;
      width: 30px;
      margin: 0 8px 0 0
    }
    p{
      margin: 0 60px 0 0;
      color:white;
    }
  }
  .announcementDetails{
    position: relative;
    background: #e5e5e5;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    &:before{
      content: '';
      position: absolute;
      top: 38%;
      left: -10px;
      margin: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 0;
      border-right: 10px solid #e5e5e5;
      padding: 0;
      width: 0;
      height: 0;
      font-size: 0;
      line-height: 0;
      @media (max-width: $screen-sm-min){
        top: -10px;
        left: 48%;
        margin: 0;
        border-top: 0;
        border-bottom: 10px solid #e5e5e5;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }
    > p{
      margin: 0px;
      color: #3a3a3a;
      >a{
        text-transform: uppercase;
        color: #54909a;
      }
    }
  }
}

footer {
  border-top: 0px solid #efefef;
  text-align: center;
  margin-top: 25px;
  padding-top: 25px;
  body {
    background: #202020;
  }
  #livechat-compact-container {
    background: rgba(84, 144, 154, 0.9);
    height: 90px;
    width: 90px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 10000;
    display: flex;
    transition: background 0.3s ease-in-out;
  }
  .btn-chat a {
    width: 100%;
    font-family: helvetica;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    display: flex;
    font-size: 12px;
    border: 0px solid #144866;
    border-radius: 0px;
    position: relative;
    transition: background 0.3s;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    img{
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin: 0 auto 10px auto;
      max-height: 42px;
      width: 42px;
    }
  }
  #livechat-compact-container:hover {
    background: rgba(84, 144, 154, 1);
    right: 15px;
  }
}

$icon-font-path: '../fonts/';


.icon .screenreader {
  display: none;
}
